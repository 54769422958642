<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <PageHeader title="Return Code Gen" backTo="tools" />
    <main class="p-6 flex space-y-6 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div
          class="absolute inset-0 bg-faint-white flex items-center justify-center rounded z-10"
          v-show="isBusy"
        >
          <spinner :color="'text-black'" :size="10" />
        </div>

        <h2 class="text-3xl mb-4">Code Generator</h2>
        <form @submit.prevent="getIvyResponseCode" autocomplete="off">
          <div class="flex flex-wrap -mx-5">
            <div class="flex flex-col w-full sm:w-1/2 mb-2 sm:mb-0 px-5">
              <label class="uppercase font-semibold text-sm">Challenge</label>
              <input
                class="bge-input bge-input-spacing rounded"
                type="text"
                required
                v-model="challengeCode"
              />
            </div>
            <div class="flex flex-col w-full sm:w-1/2 mb-2 sm:mb-0 px-5">
              <label class="uppercase font-semibold text-sm"
                >Application Type</label
              >
              <div class="inline-block relative">
                <select
                  class="bge-input bge-select rounded"
                  v-model="applicationType"
                >
                  <template v-for="(appType, i) in applicationTypes">
                    <option :key="i" :value="appType.value">{{
                      appType.label
                    }}</option>
                  </template>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end mt-5">
            <button
              type="submit"
              class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 11-7.778 7.778 5.5 5.5 0 017.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
                />
              </svg>
              <span>Get Return Code</span>
            </button>
          </div>
        </form>

        <portal to="overlay-outlet">
          <alert :showing="showResult" title="Return Code">
            <div class="w-full flex flex-col">
              <h3
                class="text-3xl font-bold bg-primary px-6 py-2 inline-block rounded-lg mb-10 mx-auto tracking-wider"
              >
                {{ responseData.response_code }}
              </h3>
              <div class="flex flex-wrap -mx-5">
                <div
                  class="flex flex-col w-full my-3 px-5"
                  :class="{ 'lg:w-1/2': responseData.challenge_info }"
                >
                  <h4 class="text-lg font-semibold">Agent / Customer:</h4>
                  <p
                    class="border rounded border-gray-400 bg-gray-100 py-2 px-4"
                  >
                    {{ responseData.org_name }}
                  </p>
                </div>
                <div
                  class="flex flex-col w-full lg:w-1/2 my-3 px-5"
                  v-if="responseData.challenge_info"
                >
                  <h4 class="text-lg font-semibold">Code Level:</h4>
                  <p
                    class="border rounded border-gray-400 bg-gray-100 py-2 px-4"
                  >
                    {{ responseData.challenge_info.code_level_description }}
                  </p>
                </div>
                <div
                  class="flex flex-col w-full my-3 px-5"
                  v-if="responseData.challenge_info"
                >
                  <h4 class="text-lg font-semibold">Serial Number:</h4>
                  <p
                    class="border rounded border-gray-400 bg-gray-100 py-2 px-4"
                  >
                    {{ responseData.challenge_info.serial_number }}
                  </p>
                </div>
              </div>
            </div>
            <button
              class="mt-10 w-full flex items-center justify-center bg-blue-200 hover:bg-blue-300 text-secondary py-2 px-4 font-semibold rounded border border-blue-400 hover:border-blue-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
              @click="showResult = false"
            >
              Done
            </button>
          </alert>
        </portal>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Alert = () => import("@/components/Alert.vue");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");

export default {
  name: "IvyCodeGenerator",
  components: {
    PageHeader,
    Alert,
    Spinner,
  },
  data() {
    return {
      challengeCode: "",
      applicationType: "SW900V4",
      responseData: {},
      isBusy: false,
      showResult: false,
      applicationTypes: [
        { label: "SW900 (V3 and below)", value: "SW900V3" },
        { label: "SW900 Ivy (V4 and above)", value: "SW900V4" },
        { label: "Manager Pro 800", value: "ManPro" },
      ],
    };
  },
  methods: {
    async getIvyResponseCode() {
      try {
        this.isBusy = true;
        var result = await this.ReturnCodeService.getIvyResponseCode(
          this.applicationType,
          this.challengeCode
        );

        this.responseData = result;
        this.challengeCode = "";
        this.isBusy = false;
        this.showResult = true;
      } catch (err) {
        console.error(JSON.stringify(err));
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { type: "danger", title: "Error", close: onClose } },
              err.data
            );
          },
          { position: "top-right" }
        );
        this.isBusy = false;
      }
    },
  },
};
</script>
